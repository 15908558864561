import './App.css';
import { Component, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <button
      onClick={() =>
        loginWithRedirect({
          scope: 'write:events read:events delete:events',
        })
      }
    >
      Log In
    </button>
  );
};

const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <button onClick={() => logout({ returnTo: window.location.origin })}>
      Log Out
    </button>
  );
};

const Profile = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    isAuthenticated && (
      <div>
        <img src={user.picture} alt={user.name} />
        <h2>{user.name}</h2>
        <p>{user.email}</p>
      </div>
    )
  );
};

const Header = () => {
  const { isAuthenticated } = useAuth0();

  if (isAuthenticated) {
    return (
      <div>
        <Profile />
        <LogoutButton />
      </div>
    );
  }

  return <LoginButton />;
};

const ShowOutputs = (props) => {
  const [outputs, setOutputs] = useState(null);

  const loadEntries = async () => {
    const response = await fetch('/api/entry', {
      headers: {
        Authorization: `Bearer ${props.accessToken}`,
      },
    });
    if (!response.ok) {
      setOutputs(null);
      console.log('got back a bad response (not ok)');
      throw 'bad response';
    }

    const out = await response.json();

    setOutputs(out);
  };

  async function remove(id) {
    const response = await fetch(`/api/entry/${id}`, {
      method: 'delete',
      headers: {
        Authorization: `Bearer ${props.accessToken}`,
      },
    });
    if (!response.ok) {
      setOutputs(null);
      console.log('got back a bad response (not ok)');
      throw 'bad response';
    }
    loadEntries();
  }

  useEffect(() => {
    loadEntries();
  }, []);

  const sub = outputs?.requestUser.sub;
  const listOfOutputs = outputs?.entries.map(
    ({ id, created_at, output_type, added_by }) => (
      <tr key={id}>
        <td>{created_at}</td>
        <td>{output_type}</td>
        <td>{added_by === sub ? 'you' : added_by}</td>
        <td>
          <button onClick={() => remove(id)}>delete</button>
        </td>
      </tr>
    )
  );
  return (
    <div>
      <button onClick={loadEntries}>Reload Outputs</button>

      <table>
        <thead>
          <tr>
            <th>Created At</th>
            <th>Output Type</th>
            <th>Added By</th>
            <th></th>
          </tr>
        </thead>
        <tbody>{listOfOutputs}</tbody>
      </table>
    </div>
  );
};

const Main = () => {
  const { isAuthenticated, getAccessTokenSilently, getAccessTokenWithPopup } =
    useAuth0();
  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    async function getToken() {
      if (!isAuthenticated) return;
      const at = await getAccessTokenSilently({
        // const at = await getAccessTokenWithPopup({
        audience: 'https://crusher.ddunlop.com/',
        scope: 'write:events read:events delete:events',
      });
      setAccessToken(at);
    }

    if (!accessToken) {
      getToken();
    }
  });

  if (isAuthenticated) {
    if (!accessToken) {
      return 'loading access token';
    }
    return (
      <div>
        <Output outputType="pee" accessToken={accessToken}>
          Pee
        </Output>
        <Output outputType="poop" accessToken={accessToken}>
          Poop
        </Output>
        <div>
          <ShowOutputs accessToken={accessToken} />
        </div>
      </div>
    );
  }
  return 'please log in';
};

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Header />
      </header>
      <h1>Crusher Tracks</h1>
      <Main />
    </div>
  );
}

const Output = ({ accessToken, outputType, children }) => {
  const [submitting, setSubmitting] = useState(false);

  const sendOutput = async () => {
    console.log('sending', outputType);
    setSubmitting(true);
    const response = await fetch('/api/entry', {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ outputType }),
    });
    if (!response.ok) {
      setSubmitting(false);
      throw 'bad response';
    }

    setSubmitting(false);
  };

  return (
    <button className="square" disabled={submitting} onClick={sendOutput}>
      {children}
    </button>
  );
};

export default App;
